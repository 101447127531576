import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    /* Please see 
    https://www.figma.com/file/WLpQdBNU9fIkxqA1DYaP6O/C1-%E2%80%A2-Sky-Blue-UI-Tool-Kit
    for reference */
    :root {
        /* Noto Sans Fonts */
        --font-family: "Noto Sans";

        /* Font Weights */
        --weight-regular: 400;
        --weight-medium: 600;
        --weight-bold: 700;
        --weight-black: 900;

        /* Layout Spacing */
        --space-4px: 4px; /* Best to avoid using but here as crutch */
        --space-8px: 8px;
        --space-16px: 16px;
        --space-24px: 24px;
        --space-32px: 32px;
        --space-40px: 40px;
        --space-48px: 48px;
        --space-56px: 56px;
        --space-64px: 64px;
        --space-72px: 72px;
        --space-80px: 80px;
        --space-120px: 120px;

        /* Primary Colors */
        --color-primary-dark: #0059B8;
        --color-primary-dark-translucent: #0059B81F;
        --color-primary-default: #001730;
        --color-primary-light: #ADD5FF;
        --color-primary-darker-translucent: rgba(173, 216, 255, 0.8);
        --color-primary-light-2: #5CABFF;
        --color-primary-translucent: #C2DFFF;
        --color-primary-gradient: #F0F1FA;
        --color-primary-gradient-2: #F6F7FC;

        /* Secondary Colors */
        --color-secondary-dark: #25A7A1;
        --color-secondary-default: #4AD7D1;
        --color-secondary-default-30: #4AD7D14D;
        --color-secondary-light: #8AE5E1;
        --color-secondary-light-2: #68DEB3;
        --color-secondary-translucent: rgba(74, 215, 209, 0.3);

        /* Tertiary Colors */
        --color-tertiary-dark: #F53900;
        --color-tertiary-default: #FF6A3D;
        --color-tertiary-default-30: #FF6A3D4D;
        --color-tertiary-light: #FFB199;
        --color-tertiary-translucent: rgba(255, 106, 61, 0.3);
        --color-tertiary-extra-light: #ffe1e9;

        /* System Colors */
        --color-success-default: #4AD7D1;

        --color-danger-dark: #890627;
        --color-danger-default: #CE0D3D;

        --color-warning-dark: #a66817; /* According to Kobi, this color will be changed and utilized eventually */
        --color-warning-default: #D3851D;

        --color-info-dark: #FFC857; /* According to Kobi, this color will be changed eventually */
        --color-info-default: #D4E9FF; /* According to Kobi, this color will be changed eventually */

        /* Neutral Colors */
        --color-neutral-white: #FFFFFF;
        --color-neutral-96: #F0F2F5;
        --color-neutral-95: #D7DEE9;
        --color-neutral-90: #E8E8E8; /* TODO: FEL-607 Discard when all references are removed */
        --color-neutral-53: #BDC4D0;
        --color-neutral-42: #8D939D;
        --color-neutral-23: #575C64;
        --color-neutral-black: #000A14;
        --color-neutral-black-25: #000A14BF;

         /* Media Queries */
        --media-sm: 480px;
        --media-md: 900px;
        --media-lg: 1024px;
        --media-xl: 1440px;

        /* Box Shadows */
        --box-shadow-default: 0px 0px 24px rgba(3, 15, 29, 0.08);
        --box-shadow-deep: 0px 0px 32px rgba(3, 15, 29, 0.2);

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    body {
        background: var(--color-primary-gradient-2);
        font-family: var(--font-family), -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        margin: 0;
        padding:0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}`;

export const GlobalStylesWrapper = () => {
  return <GlobalStyles />;
};
