import {
  browserTracingIntegration,
  init,
  replayIntegration,
  ErrorBoundary as SentryErrorBoundary,
  browserProfilingIntegration,
} from "@sentry/react";
import { FallbackComponent } from "components/Blocks/ErrorBoundary/FallbackComponent";
import { ignoredErrors } from "./ignoredErrors";

function isFilteredRequestError(event) {
  const mainAndMaybeCauseErrors = event.exception?.values?.slice(-2) ?? [];
  for (const error of mainAndMaybeCauseErrors) {
    const is404 =
      (error?.type === "NotFoundError" || error?.type === "ChunkLoadError") &&
      Boolean(error?.value.match("(GET|POST|PUT|DELETE) .* 404"));
    const is429 =
      error?.type === "TooManyRequestsError" &&
      Boolean(error?.value.match("(GET|POST|PUT|DELETE) .* 429"));

    if (is404 || is429) return true;
  }
  return false;
}

init({
  dsn: "https://e08dd52740ea476ea826b031098422d3@o1174160.ingest.sentry.io/6335799",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    browserTracingIntegration({
      tracePropagationTargets: [
        "training-server.nprd.correlation-one.com",
        "training-server.correlation-one.com",
        "training-bff.nprd.correlation-one.com",
        "training-bff.correlation-one.com",
      ],
    }),
    replayIntegration({
      sessionSampleRate: 0,
      errorSampleRate: 1.0,
      maskAllText: false,
      networkDetailAllowUrls: [/.*/],
    }),
    browserProfilingIntegration(),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE_VERSION,
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,

  beforeSend(event, _hint) {
    if (isFilteredRequestError(event)) {
      return null;
    }
    return event;
  },

  ignoreErrors: ignoredErrors.map((e) => e.error),
});

export const ErrorBoundary = ({ children }) => {
  return (
    <SentryErrorBoundary fallback={FallbackComponent}>
      {children}
    </SentryErrorBoundary>
  );
};
