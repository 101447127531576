import styled from "styled-components/macro";
import { VFlex } from "components/Atoms/Layouts/Flex";
import { ChunkErrorModal } from "__utils__/ChunkErrorModal";

const ChunkErrorFallback = () => {
  return (
    <LayoutContainer align={"center"} justify={"center"}>
      <VFlex justify={"center"} align={"center"} gap={"16"}>
        <ChunkErrorModal isModalOpen />
      </VFlex>
    </LayoutContainer>
  );
};

const LayoutContainer = styled(VFlex)`
  background: var(--color-neutral-white);
  color: var(--color-neutral-white);
  height: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
`;

export { ChunkErrorFallback };
