/**
 * API Request Configurations
 *
 * This file exports a set of functions that return request configurations for interacting with the BFF API.
 * These configurations are meant to be used with an Axios client to make API requests to the backend for frontend.
 * Each function returns an object containing the URL, HTTP method, and any required payload data.
 *
 * Usage Example:
 * ```javascript
 * import axios from 'axios';
 * import { createSubmission } from 'bffApi';
 *
 * const response = await axios(createSubmission(payload));
 * ```
 */

const API_VERSION = "api/v1";

export const createSubmission = (payload) => ({
  url: `${API_VERSION}/submissions`,
  method: "post",
  data: payload,
});

export const createSignatureFileURL = (payload) => ({
  url: `/${API_VERSION}/attachments/upload-url`,
  method: "post",
  data: payload,
});

export const launchCodioLti = (contentType, id) => ({
  url: `${API_VERSION}/codio/lti-launch`,
  method: "post",
  data: {
    object_type: contentType,
    object_id: id,
  },
});

export const getSupportData = (slug) => ({
  url: `${API_VERSION}/programs/${slug}/support`,
  method: "get",
});

export const getLandingPageData = () => ({
  url: `${API_VERSION}/my-programs`,
  method: "get",
});

export const getPDPageData = (slug) => ({
  url: `${API_VERSION}/programs/${slug}/professional-development`,
  method: "get",
});

export const getResourceCategories = (slug) => ({
  url: `${API_VERSION}/programs/${slug}/resource-categories`,
  method: "get",
});

export const getProgramContent = (slug) => ({
  url: `${API_VERSION}/programs/${slug}/materials`,
  method: "get",
});

export const getGradeReportData = (assignmentId, gradeId = null) => ({
  url: `${API_VERSION}/assignments/${assignmentId}/grade-report?grade_id=${gradeId}`,
  method: "get",
});

export const getAssessmentAttemptUrl = (slug, assignmentId) => ({
  url: `${API_VERSION}/programs/${slug}/assignments/${assignmentId}/assessment-attempt-url`,
  method: "get",
});

export const getAssignments = (slug) => ({
  url: `${API_VERSION}/programs/${slug}/assignments`,
  method: "get",
});

export const downloadSubmission = (downloadUrl) => ({
  url: downloadUrl,
  method: "get",
});
